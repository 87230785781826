import { LoadingStates } from '@components/button';
import Icon from '@components/icon';
import { Input, InputErrorMessage } from '@components/input';
import { Modal } from '@components/modal';
import Text from '@components/text';
import { checkIsBainUser, getUserHelpdeskMail } from '@util/email';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { PROJECT } from './constants';

import Fields from './Fields';
import styles from './ProjectsModal.module.scss';

const ProjectsModal = ({
  handleOk,
  handleCancel,
  isModalVisible,
  projectName,
  setProjectName,
  isProjectNameInvalid,
  projectCaseCode,
  setProjectCaseCode,
  isCaseCodeInvalid,
  setIsCaseCodeInvalid,
  user,
  modalContent,
  isLoading,
  formFields,
  setFormFields,
  disabled,
  setDisabled,
  caseTypeOptions,
  caseTypeOptionsPayg,
  regionOptions,
  officeOptions,
  capabilityOptions,
  industriesOptions,
  isPaygUser,
}) => {
  const userHelpdeskMail = getUserHelpdeskMail(checkIsBainUser(user.email));

  useEffect(() => {
    if (modalContent === PROJECT.RENAME) {
      // if available, case code is prepended to the project name e.g. '[po78] Test Project'
      const caseCode = projectName?.match(/(?:^\[(.*?)\])/);

      if (caseCode && Array.isArray(caseCode)) {
        setProjectName(state => {
          if (caseCode && state.split('] ')) {
            return state.split('] ')[1];
          }
          return state;
        });
        setProjectCaseCode(caseCode[1]);
      }
    }
  }, [modalContent, projectName, setProjectCaseCode, setProjectName]);

  useEffect(() => {
    if (modalContent === PROJECT.CREATE) {
      setProjectCaseCode('');
    }
  }, [modalContent, setProjectCaseCode]);

  const [isCaseCodeValid, setIsCaseCodeValid] = useState(false);

  // JSX
  return (
    <Modal
      className={styles.projectsModal}
      contentClassName={
        modalContent === PROJECT.DELETE
          ? styles.contentClassNameSmall
          : styles.contentClassName
      }
      headerText={
        modalContent === PROJECT.DELETE
          ? 'Are you Sure?'
          : modalContent === PROJECT.RENAME
          ? 'Rename project'
          : 'New Project'
      }
      hasCloseButton={false}
      centered
      footerContent={
        <span
          style={{
            marginRight: 'auto',
            marginLeft: '40px',
            color: '#667c8a',
          }}
        >
          (*)Required field
        </span>
      }
      footerButtonPropsList={[
        {
          title:
            modalContent === PROJECT.DELETE
              ? 'Confirm'
              : modalContent === PROJECT.RENAME
              ? 'Rename'
              : 'Create Project',
          type: 'primary',
          onClick: handleOk,
          loadingState: isLoading
            ? LoadingStates.LOADING
            : LoadingStates.NOT_LOADING,
          disabled:
            modalContent === PROJECT.DELETE
              ? false
              : modalContent === PROJECT.RENAME
              ? projectName.length
                ? checkIsBainUser(user.email)
                  ? disabled
                  : false
                : true
              : modalContent === PROJECT.CREATE
              ? projectName.length
                ? checkIsBainUser(user.email)
                  ? disabled
                  : isPaygUser
                  ? !formFields.caseType.length > 0
                  : false //check if the user is PAYG and validate the caseType
                : true
              : false,
        },
        { title: 'Back', type: 'ghost', onClick: handleCancel },
      ]}
      maskStyle={{ background: 'rgba(0, 0, 0, 0.9)' }}
      isVisible={isModalVisible}
      contentScrollable={modalContent !== PROJECT.DELETE}
      contentComponent={
        <>
          {modalContent === PROJECT.DELETE ? (
            <div className={styles.fieldSet}>
              <Text type="BodySmallRegular">
                The project {projectName} will be permanently deleted.
              </Text>
            </div>
          ) : (
            <>
              <div className={styles.fieldSet}>
                <div className={classnames(styles.font, styles.textLabel)}>
                  {modalContent === PROJECT.CREATE
                    ? 'New Project Name*'
                    : 'Name of your project'}
                </div>
                <Input
                  placeholder="Type project name here"
                  className={
                    isProjectNameInvalid
                      ? styles.textInputError
                      : styles.textInput
                  }
                  onChange={e => setProjectName(e.target.value)}
                  value={projectName}
                  // onKeyDown={e => {
                  //   if (e.key === 'Enter') {
                  //     handleOk();
                  //   }
                  // }}
                />
                {isProjectNameInvalid && (
                  <InputErrorMessage errorMessage="Please enter a project name." />
                )}
              </div>
              {(checkIsBainUser(user.email) || isPaygUser) && (
                <CaseCodeInputField
                  isCaseCodeInvalid={isCaseCodeInvalid}
                  setIsCaseCodeInvalid={setIsCaseCodeInvalid}
                  projectCaseCode={projectCaseCode}
                  setProjectCaseCode={setProjectCaseCode}
                  handleOk={handleOk}
                  view="insightsHub"
                  setDisabled={setDisabled}
                  modalContent={modalContent}
                  isCaseCodeValid={isCaseCodeValid}
                  setIsCaseCodeValid={setIsCaseCodeValid}
                  userHelpdeskMail={userHelpdeskMail}
                  isPaygUser={isPaygUser}
                />
              )}
              {(checkIsBainUser(user.email) || isPaygUser) && (
                <Fields
                  setDisabled={setDisabled}
                  formFields={formFields}
                  setFormFields={setFormFields}
                  caseTypeOptions={caseTypeOptions}
                  caseTypeOptionsPayg={caseTypeOptionsPayg}
                  regionOptions={regionOptions}
                  officeOptions={officeOptions}
                  capabilityOptions={capabilityOptions}
                  industriesOptions={industriesOptions}
                  isPaygUser={isPaygUser}
                />
              )}
            </>
          )}
        </>
      }
    />
  );
};

ProjectsModal.propTypes = {
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  isModalVisible: PropTypes.bool,
  isProjectNameInvalid: PropTypes.bool,
  setProjectName: PropTypes.func,
  projectName: PropTypes.string,
  isCaseCodeInvalid: PropTypes.bool,
  setProjectCaseCode: PropTypes.func,
  projectCaseCode: PropTypes.string,
  user: PropTypes.object,
  modalContent: PropTypes.oneOf(Object.values(PROJECT)),
  isLoading: PropTypes.bool,
  setDisabled: PropTypes.func,
  disabled: PropTypes.bool,
};

export const CaseCodeInputField = ({
  isCaseCodeInvalid,
  projectCaseCode,
  setProjectCaseCode,
  handleOk,
  view,
  modalContent,
  isCaseCodeValid,
  setIsCaseCodeValid,
  userHelpdeskMail,
  isPaygUser,
  // setDisabled,
}) => {
  const [hasInteractedWithCaseCode, setHasInteractedWithCaseCode] = useState(
    false
  );

  const caseCodeValidation = code => {
    const invalidCodes = ['DEMO', 'TEST', 'ABCD'];
    const isSameSequence = /^(.)\1+$/.test(code);
    const isFourDigitsWithLettersAndNumbers = /^[a-zA-Z]+\d{4}$/.test(code);

    if (
      invalidCodes.includes(code.toUpperCase()) ||
      isSameSequence ||
      ((code.length === 3 || code.length === 4) &&
        !isFourDigitsWithLettersAndNumbers)
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const validity = caseCodeValidation(projectCaseCode);
    (modalContent === PROJECT.CREATE || modalContent === PROJECT.RENAME) &&
      setIsCaseCodeValid(validity);
  }, [modalContent, projectCaseCode, setIsCaseCodeValid]);

  const getHowItWorksText = (view, isPaygUser) => {
    let text = <></>;
    if (view === 'jobPostings') {
      return text;
    }
    if (view === 'insightsHub' && !isPaygUser) {
      text = (
        <p style={{ margin: 0 }}>
          <b>
            The first 48 hours (or until data is downloaded) are considered the
            "Testing Period" and are free of charge.
          </b>{' '}
          After this period, the following fees apply:
          <br />
          <ul
            style={{
              listStyle: 'initial',
              margin: '10px 0',
              paddingLeft: '30px',
            }}
          >
            <li>
              <b>$1,500</b> fee for client development cases (e.g., CD, IP, or
              other internal case work)
            </li>
            <li>
              <b>$9,500</b> for client projects (e.g., GP or PEG cases)
            </li>
          </ul>
          For questions, contact us at{' '}
          <a
            href={`mailto:` + userHelpdeskMail}
            target="_blank"
            onClick={e => e.stopPropagation()}
          >
            {userHelpdeskMail}.
          </a>{' '}
          <br />
          <br />
          <b>Important Note on Case Codes:</b>
          <ul
            style={{
              listStyle: 'initial',
              margin: '10px 0',
              paddingLeft: '30px',
            }}
          >
            <li>
              {' '}
              Case codes like <b>DEMO, TEST, ABCD,</b> or any sequence of
              identical numbers or letters are <b>invalid</b>.
            </li>
            <li>
              For 4-character case codes, only combinations of letters and
              numbers are allowed.
            </li>
          </ul>
        </p>
      );
    } else if (view === 'databox' && !isPaygUser) {
      text = (
        <p style={{ margin: 0 }}>
          Exploring the Aura platform is free. However, if you share data with
          clients or use it on a CD or Internal (IP) case, fees apply:
          <br />
          <ul
            style={{
              listStyle: 'initial',
              margin: '10px 0',
              paddingLeft: '30px',
            }}
          >
            <li>
              <b>$0.75 per profile (up to 5,000 profiles)</b>
            </li>
            <li>
              <b>$5,000 flat fee (for 5,001–150,000 profiles)</b>
            </li>
          </ul>
          For questions, contact{' '}
          <a
            href={`mailto:` + userHelpdeskMail}
            target="_blank"
            onClick={e => e.stopPropagation()}
          >
            {userHelpdeskMail}
          </a>
          .{' '}
        </p>
      );
    } else if (isPaygUser) {
      text = (
        <p style={{ margin: 0, width: 500 }}>
          Aura usage becomes chargeable after a free test period of 48 hours. As
          you create the project, lookout for an email from Aura: within 48
          hours of receiving the email, please let the Aura team know if you
          don't plan to use Aura for your project, and we'll cancel the project
          for you.
        </p>
      );
    }
    return (
      <>
        <div className={classnames(styles.font, styles.textWordingTitle)}>
          How it works
        </div>
        {text}
      </>
    );
  };

  const howItWorksText = getHowItWorksText(view, isPaygUser);

  return (
    <div className={styles.fieldSet}>
      <div>
        {!isPaygUser && (
          <>
            <div className={classnames(styles.font, styles.textLabel)}>
              Project code*
            </div>
            <Input
              className={
                isCaseCodeInvalid ? styles.textInputError : styles.textInput
              }
              placeholder="Type project code here"
              value={projectCaseCode}
              onChange={e => {
                setProjectCaseCode(e.target.value);
              }}
              rightComponent={
                !['insightsHub', 'databox'].includes(view) && (
                  <Tooltip
                    overlayClassName={styles.tooltipContainer}
                    placement="bottom"
                    color="var(--gray-100)"
                    title={() => (
                      <div className={styles.tooltipContent}>
                        <div className={styles.tooltipText}>
                          Job posting access is included in any Insights Hub
                          project case code. When job posting is accessed alone,
                          there is a $5K charge to the case code. Please contact{' '}
                          <span className={styles.tooltipTextEmail}>
                            {userHelpdeskMail}
                          </span>{' '}
                          if you don't find the data you need and would like to
                          request a custom data pull, or if you don't end up
                          using this data and want the charge to be waived, or
                          for any questions.
                        </div>
                      </div>
                    )}
                  >
                    <Icon
                      icon="infoIcon"
                      className={
                        isCaseCodeInvalid
                          ? styles.infoIconButtonError
                          : styles.infoIconButton
                      }
                      viewBox="-0.5 1 13 10"
                    />
                  </Tooltip>
                )
              }
              onKeyDown={e => {
                if (e.key === 'Enter' && view === 'jobPostings') {
                  handleOk();
                }
              }}
              onBlur={() => setHasInteractedWithCaseCode(true)}
            />
          </>
        )}
        {((isCaseCodeInvalid && view === 'jobPostings') ||
          (!isCaseCodeValid &&
            hasInteractedWithCaseCode &&
            view !== 'jobPostings')) && (
          <InputErrorMessage errorMessage="Case code must be alphanumeric, 3 to 4 characters long." />
        )}
      </div>
      <Text
        type="LegendRegular"
        style={{
          display: 'block',
          marginTop: view === 'insightsHub' ? '0px' : '20px',
          color: 'var(--gray-500)',
          textAlign: 'justify',
        }}
      >
        {howItWorksText}
      </Text>
    </div>
  );
};

CaseCodeInputField.propTypes = {
  isCaseCodeInvalid: PropTypes.bool,
  projectCaseCode: PropTypes.string,
  setProjectCaseCode: PropTypes.func,
  handleOk: PropTypes.func,
  setDisabled: PropTypes.func,
};

export default ProjectsModal;
